<template>
  <div
    @click="personalizeVisible = false"
    class="flex flex-col w-full rounded-md relative"
    :class="[
      fullWidth === 'true' ? '' : 'md:max-w-[396px]',
      color ? 'bg-neutral-600' : '',
      label ? 'gap-3' : '',
    ]"
    v-outside
  >
    <div class="flex items-center gap-3">
      <p
        :class="
          labelClasses
            ? labelClasses
            : 'font-semibold text-sm leading-none text-neutral-800 dark:text-white'
        "
      >
        {{ label }}
      </p>
      <svg
        v-if="toolTipText"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5625 6.5625L6.5867 6.5504C6.92102 6.38324 7.29745 6.6852 7.2068 7.04782L6.7932 8.70218C6.70255 9.0648 7.07898 9.36676 7.4133 9.1996L7.4375 9.1875M12.25 7C12.25 9.89949 9.89949 12.25 7 12.25C4.10051 12.25 1.75 9.89949 1.75 7C1.75 4.10051 4.10051 1.75 7 1.75C9.89949 1.75 12.25 4.10051 12.25 7ZM7 4.8125H7.00438V4.81688H7V4.8125Z"
          stroke="#525252"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="relative">
      <textarea
        class="font-normal outline-none text-sm border box-border transition-all ease-in-out duration-150 w-full rounded-md bg-white dark:bg-neutral-800 content-scroll"
        :class="[
          textColor
            ? textColor
            : 'text-neutral-600 placeholder:text-neutral-400 focus:border-blue-600 dark:text-white dark:placeholder:text-neutral-400',
          customHeight ? customHeight : '',
          !isResize && 'resize-none',
          customPadding ? `${customPadding} leading-none` : 'p-4 ',
          error
            ? 'border-red-600 focus:border-red-600 hover:border-red-600'
            : disabled
            ? 'focus:border-neutral-400'
            : 'focus:border-blue-600 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700',
        ]"
        id="emojiID"
        ref="textAreaCursorPos"
        cols="30"
        :placeholder="placeHolder"
        :rows="customRows ?? 3"
        :value="modelValue"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup="handleCharacterLimit"
        @click="showEmoji = false"
        :name="name"
      ></textarea>
      <!-- Character Limit display -->
      <div
        v-if="showAssistance"
        @click="(AiTool.menu = !AiTool.menu), (AiTool.promptModal = false)"
        class="absolute bottom-2.5 right-3 cursor-pointer text-neutral-800 dark:text-neutral-200"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" class="holder_f19n375c">
          <path
            d="M14.256,7.346C15.403,7.699 16.301,8.597 16.654,9.744C16.759,10.085 17.241,10.085 17.346,9.744C17.699,8.597 18.597,7.699 19.744,7.346C20.085,7.241 20.085,6.759 19.744,6.654C18.597,6.301 17.699,5.403 17.346,4.256C17.241,3.915 16.759,3.915 16.654,4.256C16.301,5.403 15.403,6.301 14.256,6.654C13.915,6.759 13.915,7.241 14.256,7.346ZM17,5.339C17.392,6.034 17.967,6.608 18.661,7C17.967,7.392 17.392,7.966 17,8.661C16.608,7.967 16.034,7.392 15.339,7C16.034,6.608 16.608,6.034 17,5.339Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0"
          ></path>
          <path
            d="M6.426,13.577C8.338,14.165 9.835,15.662 10.423,17.574C10.598,18.142 11.402,18.142 11.577,17.574C12.165,15.662 13.662,14.165 15.574,13.577C16.142,13.402 16.142,12.598 15.574,12.423C13.662,11.835 12.165,10.338 11.577,8.426C11.402,7.858 10.598,7.858 10.423,8.426C9.835,10.338 8.338,11.835 6.426,12.423C5.858,12.598 5.858,13.402 6.426,13.577ZM11,9.69C11.693,11.138 12.862,12.307 14.31,13C12.862,13.693 11.693,14.862 11,16.31C10.307,14.862 9.138,13.693 7.69,13C9.138,12.307 10.307,11.138 11,9.69Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0"
          ></path>
        </svg>
        <!-- AI -->
      </div>
      <div
        v-if="AiTool.menu && showAssistance"
        class="absolute py-1 z-10 right-2.5 w-[100px] bg-white dark:bg-neutral-800 rounded-md border dark:border-neutral-700 mt-1 min-w-fit block select-none"
      >
        <p
          v-if="modelValue"
          v-for="item in AiTool.editOPtions"
          @click.stop="
            handleAIEditor(item), (selectedOption = item), (AiTool.menu = false)
          "
          class="text-sm text-neutral-800 dark:text-neutral-200 cursor-pointer hover:bg-blue-50 dark:hover:bg-blue-400/10 hover:text-blue-600 dark:hover:text-blue-400 py-1 px-3"
        >
          {{ item }}
        </p>
        <p
          @click="(AiTool.menu = false), (AiTool.promptModal = true)"
          v-else
          class="text-sm text-neutral-800 dark:text-neutral-200 cursor-pointer hover:bg-blue-50 dark:hover:bg-blue-400/10 hover:text-blue-600 dark:hover:text-blue-400 py-1 px-3"
        >
          Generate with AI
        </p>
      </div>
      <div
        v-if="AiTool.promptModal && showAssistance"
        class="absolute w-full bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 rounded-md shadow z-20 mt-1"
      >
        <div
          v-if="AiTool.aiResponse.length > 0"
          class="flex items-center justify-between px-5 py-3 border-b border-neutral-200 dark:border-neutral-700 text-neutral-800 dark:text-neutral-200"
        >
          <p>
            {{ modelValue ? selectedOption : "Generate with AI" }}
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            @click="handlePromptClose()"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 shrink-0 cursor-pointer"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div
          v-if="AiTool.aiResponse.length > 0"
          class="px-5 py-3 flex flex-col gap-5 max-h-[300px] overflow-auto content-scroll"
        >
          <div
            v-for="(item, index) in AiTool.aiResponse"
            class="text-xs text-neutral-800 dark:text-neutral-200 flex flex-col gap-3"
          >
            <div
              v-if="item.prompt.type == 'human'"
              class="flex items-center gap-2"
            >
              <img src="/icons/aconversion_icon.png" />
              <p class="text-sm text-neutral-800 dark:text-neutral-200">
                {{ item.prompt.message }}
              </p>
            </div>
            <div v-if="item.aiResponse.type == 'bot'" class="flex gap-2">
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                class="-ml-1 shrink-0 text-neutral-800 dark:text-neutral-200"
              >
                <path
                  d="M14.256,7.346C15.403,7.699 16.301,8.597 16.654,9.744C16.759,10.085 17.241,10.085 17.346,9.744C17.699,8.597 18.597,7.699 19.744,7.346C20.085,7.241 20.085,6.759 19.744,6.654C18.597,6.301 17.699,5.403 17.346,4.256C17.241,3.915 16.759,3.915 16.654,4.256C16.301,5.403 15.403,6.301 14.256,6.654C13.915,6.759 13.915,7.241 14.256,7.346ZM17,5.339C17.392,6.034 17.967,6.608 18.661,7C17.967,7.392 17.392,7.966 17,8.661C16.608,7.967 16.034,7.392 15.339,7C16.034,6.608 16.608,6.034 17,5.339Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0"
                ></path>
                <path
                  d="M6.426,13.577C8.338,14.165 9.835,15.662 10.423,17.574C10.598,18.142 11.402,18.142 11.577,17.574C12.165,15.662 13.662,14.165 15.574,13.577C16.142,13.402 16.142,12.598 15.574,12.423C13.662,11.835 12.165,10.338 11.577,8.426C11.402,7.858 10.598,7.858 10.423,8.426C9.835,10.338 8.338,11.835 6.426,12.423C5.858,12.598 5.858,13.402 6.426,13.577ZM11,9.69C11.693,11.138 12.862,12.307 14.31,13C12.862,13.693 11.693,14.862 11,16.31C10.307,14.862 9.138,13.693 7.69,13C9.138,12.307 10.307,11.138 11,9.69Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0"
                ></path>
              </svg>
              <div class="flex flex-col gap-4 mt-1">
                <p class="text-sm text-neutral-800 dark:text-neutral-200">
                  {{ item.aiResponse.message }}
                </p>
                <div
                  v-if="AiTool.aiResponse[index - AiTool.aiResponse.length + 1]"
                  class="flex items-center gap-2"
                >
                  <button
                    @click="
                      (modelValue = item.aiResponse.message),
                        $emit('update:modelValue', modelValue)
                    "
                    class="text-xs border border-neutral-200 dark:border-neutral-700 py-0.5 px-2 rounded-md text-neutral-800 dark:text-neutral-200"
                  >
                    Replace
                  </button>
                  <button
                    @click="
                      (modelValue += item.aiResponse.message),
                        $emit('update:modelValue', modelValue)
                    "
                    class="text-xs border border-neutral-200 dark:border-neutral-700 py-0.5 px-2 rounded-md text-neutral-800 dark:text-neutral-200"
                  >
                    Insert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative px-5 py-3 flex flex-col gap-4">
          <div
            v-if="
              selectedOption == 'Translate' &&
              modelValue &&
              AiTool.aiResponse.length == 0
            "
            class="flex flex-col gap-2"
          >
            <ReusableUISearchDropdown
              full-width="true"
              label="Select Language"
              :options="formatSelectData(getLanguages)"
              :value="language"
              v-model="language"
            />
            <ReusableUIButtonsButtonPrimary
              @click="
                (AiTool.aiResponse = []),
                  handleAI(
                    `please translate this text in ${language}: ${modelValue}`
                  )
              "
              :loading="loadingPrompt"
              size="medium"
              text="Translate"
              class="ml-auto w-[100px]"
            />
          </div>
          <div class="relative" v-if="selectedOption !== 'Translate'">
            <div class="flex flex-col gap-3">
              <p
                v-if="!modelValue"
                class="font-semibold text-sm/none text-neutral-800 dark:text-white cursor-default"
              >
                {{ AskQuestion }}
              </p>
              <input
                type="text"
                @change="(val) => (promptValue = val.target.value)"
                :value="promptValue"
                placeholder="Enter your prompt"
                class="outline-none text-sm text-neutral-600 placeholder:text-neutral-400 bg-white border border-neutral-300 dark:border-neutral-700 dark:hover:border-neutral-600 bg-transparent hover:border-neutral-400 w-full dark:bg-neutral-800 dark:text-neutral-400 px-4 py-3 focus:border-blue-600 dark:focus:border-blue-400 rounded-md"
              />
            </div>
            <div
              v-if="loadingPrompt"
              class="absolute w-8 h-7 rounded-md bg-blue-600 hover:bg-blue-700 dark:bg-blue-400 dark:hover:bg-blue-500 transition-all ease-out duration-200 cursor-pointer right-3 bottom-2.5 flex justify-center items-center"
            >
              <svg
                aria-hidden="true"
                class="inline w-3.5 h-3.5 text-gray-200 animate dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
            <div
              v-else
              class="absolute w-8 h-7 rounded-md bg-blue-600 hover:bg-blue-700 dark:bg-blue-400 dark:hover:bg-blue-500 transition-all ease-out duration-200 cursor-pointer right-3 bottom-2.5 flex justify-center items-center"
              @click="handleAI()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-4 h-4 text-white"
              >
                <path
                  d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="relative text-neutral-800 dark:text-neutral-200">
        <p
          class="absolute bottom-4.5 opacity-50 text-xs"
          v-if="characterLimit > 0"
          :class="[
            personalize && displayEmoji
              ? 'right-16'
              : personalize || displayEmoji
              ? 'right-10'
              : 'right-4',
          ]"
        >
          {{ characters }} / {{ characterLimit }}
        </p>
      </div>
      <!-- Personalize options -->
      <div class="relative">
        <svg
          v-if="personalize"
          class="absolute bottom-5 cursor-pointer"
          :class="[
            personalizeVisible === true
              ? 'text-blue-600 dark:text-blue-400'
              : 'text-neutral-500 dark:text-white',
            displayEmoji ? 'right-10 ' : 'right-4',
          ]"
          @click.stop="
            [(personalizeVisible = !personalizeVisible), (showEmoji = false)]
          "
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 5V7M12 7V9M12 7H14M12 7H10M8.5 4.25C8.5 5.49264 7.49264 6.5 6.25 6.5C5.00736 6.5 4 5.49264 4 4.25C4 3.00736 5.00736 2 6.25 2C7.49264 2 8.5 3.00736 8.5 4.25ZM2.00061 12.8229C2.00021 12.7986 2 12.7743 2 12.75C2 10.4028 3.90279 8.5 6.25 8.5C8.59721 8.5 10.5 10.4028 10.5 12.75V12.7517C10.5 12.7755 10.4998 12.7992 10.4994 12.8229C9.25824 13.5702 7.80432 14 6.25 14C4.69568 14 3.24176 13.5702 2.00061 12.8229Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div
          v-if="personalizeVisible && personalize"
          class="absolute bg-white rounded-lg divide-y divide-neutral-200 dark:divide-neutral-600 overflow-hidden shadow-md dark:bg-neutral-800 z-30 border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 transition-all ease-in-out duration-150 dark:hover:border-neutral-600"
          :class="[
            displayEmoji ? 'right-10' : 'right-4',
            position === 'bottom' ? '-top-3' : 'bottom-[43px]',
            !customSubscriber ? 'h-[320px] w-[350px]' : 'w-[95%]',
          ]"
        >
          <div class="flex p-4 bg-white w-full dark:bg-neutral-800">
            <div
              class="flex flex-row w-full items-center text-center"
              v-for="gkey in groupKeys"
              :key="gkey"
            >
              <p
                @click.stop="tabKey = gkey.toLowerCase()"
                class="flex w-full justify-center pb-2 border-b text-sm leading-none transition-all ease-in-out duration-700 cursor-pointer"
                :class="
                  tabKey === gkey.toLowerCase()
                    ? 'text-blue-600 dark:text-blue-400 border-blue-600 dark:border-blue-400 font-semibold'
                    : 'font-normal  text-neutral-600 dark:text-neutral-200 border-neutral-200 dark:border-neutral-700'
                "
              >
                {{ capitalizeFirstLetter(gkey) }}
              </p>
            </div>
          </div>
          <!-- <div class="w-full h-0.5 my-4 bg-neutral-200" /> -->
          <div
            class="overflow-x-hidden content-scroll overflow-y-auto py-2 flex flex-col"
            :class="!customSubscriber ? 'h-[calc(100%-55px)]' : ''"
          >
            <div
              class="flex flex-col h-full cursor-pointer"
              v-for="key in currentKeysList"
              :key="key"
            >
              <p
                @click="addKeys(key)"
                class="font-normal text-xs leading-none text-neutral-600 dark:text-neutral-200 hover:text-blue-600 dark:hover:text-blue-400 transition ease-in-out duration-200 py-2 px-4 hover:bg-blue-50 dark:hover:bg-blue-400/10"
              >
                {{ key }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Emoji Picker -->
      <div class="relative">
        <svg
          class="absolute right-4 bottom-5 cursor-pointer"
          :class="
            showEmoji === true
              ? 'text-blue-600 dark:text-blue-700'
              : 'text-neutral-500 dark:text-white'
          "
          v-if="displayEmoji"
          @click="
            (showEmoji = !showEmoji), (personalizeVisible = !personalizeVisible)
          "
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.12132 9.12132C7.94975 10.2929 6.05025 10.2929 4.87868 9.12132M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM5.5 5.5C5.5 5.77614 5.38807 6 5.25 6C5.11193 6 5 5.77614 5 5.5C5 5.22386 5.11193 5 5.25 5C5.38807 5 5.5 5.22386 5.5 5.5ZM5.25 5.5H5.255V5.51H5.25V5.5ZM9 5.5C9 5.77614 8.88807 6 8.75 6C8.61193 6 8.5 5.77614 8.5 5.5C8.5 5.22386 8.61193 5 8.75 5C8.88807 5 9 5.22386 9 5.5ZM8.75 5.5H8.755V5.51H8.75V5.5Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <!-- class="absolute right-5 top-[150px] bg-white shadow-md dark:bg-neutral-800 rounded-md p-2 grid grid-cols-5 max-h-40 content-scroll overflow-y-auto gap-2 z-30 border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 transition-all ease-in-out duration-150 dark:hover:border-neutral-600" -->
        <div
          class="absolute z-30 right-5"
          v-if="displayEmoji && loadEmojiPicker"
          :class="[
            showEmoji ? 'block' : 'hidden',
            position === 'bottom' ? '-top-3' : 'bottom-[43px]',
          ]"
        >
          <LazyReusableUIEmojiPicker @selectEmoji="selectEmoji" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useSettingsStore } from "~~/store/settings";
import { storeToRefs } from "pinia";
import { useCommonStore } from "~~/store/common";
const commonStore = useCommonStore();
const { getTimelineData } = storeToRefs(commonStore);
const tabKey = ref("subscriber");
const promptValue = ref("");
const settingsStore = useSettingsStore();
const { getPersonalizeFields, getLanguages } = storeToRefs(settingsStore);
const timelinePlatform = ref(settingsStore.general.deliveryPlatform || null);
const props = defineProps({
  label: String,
  placeHolder: String,
  toolTipText: String,
  fullWidth: String,
  modelValue: String,
  size: String,
  disabled: { default: false, type: Boolean },
  color: String,
  textColor: String,
  customHeight: String,
  displayEmoji: Boolean,
  personalize: Boolean,
  labelClasses: String,
  showSubscriber: { default: true, type: Boolean },
  customSubscriber: { default: false, type: Boolean },
  position: { default: "bottom", type: String },
  customPadding: String,
  customRows: Number,
  characterLimit: { default: -1, type: Number },
  hideRegValues: { default: false, type: Boolean },
  error: { default: false, type: Boolean },
  name: { default: "", type: String },
  showAssistance: Boolean,
  helpingText: String,
  AskQuestion: String,
  isResize: { type: Boolean, default: false },
});

const registrationEntries = ref(settingsStore.registration.customForms || {});
const AiTool = ref({
  menu: false,
  promptModal: false,
  aiResponse: [],
  prompt: [],
  editOPtions: [
    "Edit with AI",
    "Fix spellings and grammar",
    "Expand More",
    "Make simpler",
    "Translate",
  ],
});
const loadingPrompt = ref(false);
const language = ref("");
const selectedOption = ref("");
const handleAI = async (prompt) => {
  loadingPrompt.value = true;
  const payload = {
    integrationID: "openai",
    prompt: prompt ? prompt : (props.helpingText += promptValue.value),
    systemPrompt: prompt ? prompt : promptValue.value,
  };
  const response = await call("/complete", "POST", payload);
  if (response) {
    let newMessage = {
      prompt: { type: "human", message: promptValue.value },
      aiResponse: {
        type: "bot",
        message: response.data?.success.replace(/['"]+/g, ""),
      },
    };
    promptValue.value = "";
    loadingPrompt.value = false;

    AiTool.value.aiResponse.push(newMessage);
  }
};
const handleAIEditor = (val) => {
  promptValue.value = props.modelValue;
  AiTool.value.promptModal = true;
  switch (val) {
    case "Fix spellings and grammar":
      AiTool.value.aiResponse = [];
      handleAI(`please fix spellings and grammar in: ${props.modelValue}`);
      break;
    case "Expand More":
      handleAI(`please give further details about: ${props.modelValue}`);
      break;
    case "Make simpler":
      handleAI(`please simplify this text: ${props.modelValue}`);
      break;

    case "Translate":
      AiTool.value.aiResponse = [];
      break;
  }
};
const handlePromptClose = () => {
  AiTool.value.promptModal = false;
  language.value = "english";
};
const showEmoji = ref(false);
const loadEmojiPicker = ref(false);
const emit = defineEmits(["update:modelValue"]);
const textAreaCursorPos = ref(null);

const personalizeVisible = ref(false);
const personalizeData = ref({});
const currentKey = ref(null);
const currentKeysList = ref({});
const groupKeys = ref(null);
const characters = ref(0);

const addKeys = (ckey) => {
  Object.entries(personalizeData.value[tabKey.value]).forEach(
    ([key, value]) => {
      if (value == ckey) {
        currentKey.value = key;
      }
    }
  );
  var curPos = textAreaCursorPos.value.selectionStart;
  let temp = props.modelValue;
  if (temp == undefined) temp = "";
  let newValue =
    "{{!" + tabKey.value.toLowerCase() + "-" + currentKey.value + "!}}";
  if (["apple_calendar", "google_calendar"].includes(currentKey.value)) {
    newValue = `{{!add_to_${currentKey.value}}}`;
  }
  emit(
    "update:modelValue",
    temp.slice(0, curPos) + newValue + temp.slice(curPos)
  );
  textAreaCursorPos.value.focus();
  personalizeVisible.value = false;
};

const selectEmoji = (emo) => {
  var curPos = textAreaCursorPos.value.selectionStart;
  let temp = props.modelValue;
  if (temp == undefined) temp = "";
  emit("update:modelValue", temp.slice(0, curPos) + emo.i + temp.slice(curPos));
  textAreaCursorPos.value.focus();
  showEmoji.value = false;
};

const handleCharacterLimit = (e) => {
  if (props.characterLimit && props.characterLimit !== -1) {
    characters.value = e.target.value.length;
    if (characters.value > props.characterLimit) {
      e.target.value = e.target.value.slice(0, props.characterLimit);
      characters.value = e.target.value.length;
      emit("update:modelValue", e.target.value);
    }
  }
};

onBeforeMount(async () => {
  await Promise.allSettled([
    settingsStore.fetchGlobalSettings({
      type: "personalize",
    }),
  ]);
  personalizeData.value = deepCloneObject(getPersonalizeFields.value);
  // Add registration entries to personalize > subscriber
  if (
    registrationEntries?.value &&
    Object.keys(registrationEntries.value).length > 0
  ) {
    Object.values(registrationEntries.value).forEach((el) => {
      personalizeData.value["subscriber"][el.name] = el.name;
    });
  }

  // Removing subscriber key
  if (props.showSubscriber == "false") {
    delete personalizeData.value["subscriber"];
    tabKey.value = "webinar";
  }

  // Only showing subscriber key
  if (props.customSubscriber) {
    if (personalizeData.value["webinar"])
      delete personalizeData.value["webinar"];
    if (personalizeData.value["replay"]) delete personalizeData.value["replay"];
    let validKeys = ["joinURL", "platformURL", "replayURL"];
    personalizeData.value["subscriber"] = Object.keys(
      personalizeData.value["subscriber"]
    ).reduce((acc, key) => {
      if (validKeys.includes(key)) {
        acc[key] = personalizeData.value["subscriber"][key];
      }
      return acc;
    }, {});
  }

  // Filtering out - Reg values
  if (props.hideRegValues) {
    const allowedPlatforms = ["zoom", "gotowebinar", "obvio", "default"];
    const platformValue =
      timelinePlatform.value && timelinePlatform.value.toLowerCase();
    if (allowedPlatforms.includes(platformValue)) {
      ["webinar", "replay"].forEach((type) => {
        const personalizeFields = personalizeData.value[type];
        if (personalizeFields) {
          Object.keys(personalizeFields).forEach((key) => {
            if (key.includes("reg-")) {
              delete personalizeFields[key];
            }
          });
        }
      });
    } else {
      // console.error("Invalid or unsupported platform:", platformValue);
    }
  }

  // Setting group keys and keys list value
  groupKeys.value = Object.keys(personalizeData.value);

  if (props?.modelValue) characters.value = props?.modelValue?.length || 0;
});

watch(
  () => {
    if (typeof window !== undefined && window) {
      loadEmojiPicker.value = true;
    }
    if (personalizeData.value) {
      Object.entries(personalizeData.value).forEach(([key, value]) => {
        if (key.toLowerCase() == tabKey.value) {
          currentKeysList.value = value;
        }
      });
    }
  },
  { immediate: true }
);

const vOutside = {
  mounted(el, binding, vnode) {
    const onClickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        showEmoji.value = false;
        personalizeVisible.value = false;
        AiTool.value.menu = false;
      }
    };
    setTimeout(() => {
      document.body.addEventListener("click", onClickOutside);
      el._onClickOutside = onClickOutside;
    }, 2000);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el._onClickOutside);
    delete el._onClickOutside;
  },
};
</script>
